import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';

@Component({
  standalone: true,
  imports: [ConfirmDialogComponent, TranslatePipe],
  selector: 'app-delete-location-dialog',
  template: `<app-confirm-dialog
    [useDangerButton]="true"
    [closeButtonText]="'PROCEED'"
    [dismissButtonText]="'CANCEL'"
  >
    <p class="danger">
      {{ 'DELETE_LOCATION_PROMPT' | translate }}
    </p>
  </app-confirm-dialog> `,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteLocationDialogComponent {}
