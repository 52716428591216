import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  inject,
  effect,
  signal,
  input,
  untracked,
  viewChild,
  computed,
  output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ILocationForm, ICountry } from '@desquare/interfaces';
import { FormGroup, FormsModule, NgForm } from '@angular/forms';
import { ProfileLocationsFragment, Location } from '@designage/gql';
import { getData as getCountryListData } from 'country-list';
import * as mapboxgl from 'mapbox-gl';
import _ from 'lodash';
import { environment } from '@desquare/environments';
import {
  CurrentUserService,
  MapboxService,
  ProfileService,
} from '@desquare/services';
import { IMapSettings } from '@desquare/interfaces';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { TranslatePipe } from '@ngx-translate/core';
import { LocationsStore } from '@desquare/stores';
import { FormDirective } from '@desquare/directives';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteLocationDialogComponent } from '../delete-location-dialog/delete-location-dialog.component';
import { Point } from 'mapbox-gl';
import {
  templateDrivenForms,
  templateDrivenFormsViewProviders,
} from '@desquare/forms';
import { DesButtonComponent } from '@desquare/ui-components/des-button.component';
import { locationFormValidation } from '@desquare/validators';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    FormDirective,
    TranslatePipe,

    templateDrivenForms,
    DesButtonComponent,
  ],
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [templateDrivenFormsViewProviders],
})
export class LocationFormComponent implements OnInit, AfterViewInit, OnDestroy {
  locationsStore = inject(LocationsStore);
  profileService = inject(ProfileService);
  modalService = inject(NgbModal);
  currentUserService = inject(CurrentUserService);
  location = input.required<ILocationForm | null>();
  mapContainer = viewChild<ElementRef>('mapboxContainer');
  ngForm = viewChild<NgForm>('form');
  optional = computed((): string[] => {
    let locations = this.locationsStore.locations().map((x) => x.name);
    if (this.location()?.name) {
      locations = locations.filter((name) => name !== this.location()?.name);
    }
    return locations;
  });
  protected readonly suite = locationFormValidation(this.optional);

  showDeleteButton = input<boolean>(true);
  showFooter = input<boolean>(true);
  locationSaved = output<ILocationForm>();

  @ViewChild('mapboxContainer') mapboxContainer!: ElementRef;

  closeSlidingPanel = output();
  closeParentModal = output<ILocationForm>();

  @Input() formId = 'locationForm';
  @Input() showMapOnly = false;
  @Input() showLocationName = true;
  @Input() showLocationSearch = true;
  @Input() isCreate = false;
  @Input() locationName!: string;

  @Input() parentFormGroup?: FormGroup;

  protected profileLocationNames = computed(() =>
    this.locationsStore.locations().map((x) => x.name.toLowerCase()),
  );
  protected formValue = signal<ILocationForm>({
    profileLocations: this.profileLocationNames(),
  });
  protected formDirty = signal<boolean>(false);
  protected formValid = signal<boolean>(false);
  protected loading = computed(() => this.locationsStore.loading);
  protected isDefaultLocation = computed(
    () => this.currentUserService.currentProfile,
  );

  protected mapboxSettings = computed((): IMapSettings => {
    return {
      container: this.mapContainer()?.nativeElement.id,
      style: this.mapboxService.DarkStyle,
      center: [
        this.formValue()?.coordinates?.x ??
          this.mapboxService.DefaultMapCoords[0],
        this.formValue()?.coordinates?.y ??
          this.mapboxService.DefaultMapCoords[1],
      ],
      zoom: this.isCreate ? 1 : 14,
    };
  });

  profileLocations: ProfileLocationsFragment[] = [];
  selectedLocation!: ProfileLocationsFragment;
  // locationForm!: FormGroup;
  countries!: ICountry[];
  map!: mapboxgl.Map;

  geocoder = new MapboxGeocoder({
    accessToken: environment.mapbox.accessToken,
    language: 'en-EN',
    mapboxgl,
  });

  marker = new mapboxgl.Marker({
    color: '#FFFFFF',
    draggable: false,
  });

  geocoderInputString!: string;
  mapboxResponse!: any;
  coordinates!: Point;

  editingLocation = signal<boolean>(false);
  isDuplicateName = signal<boolean>(false);

  constructor(private mapboxService: MapboxService) {
    effect(() => {
      if (this.location() !== null) {
        const location = this.location() as ILocationForm;

        untracked(() => {
          this.formValue.set(_.cloneDeep(location));
          this.formValue.update((values) => ({
            ...values,
            profileLocations: this.profileLocationNames(),
          }));

          this.getCoordinatesAndUpdateMap();
        });
      }
    });
    // effect(() => {
    //   console.log('this.formDirty outside: ', this.formDirty());
    // });
  }

  ngOnInit() {
    this.initVariables();
  }

  ngOnDestroy() {
    this.map?.remove();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.initMap(), 500);
  }

  initVariables() {
    this.coordinates =
      this.location() !== null
        ? (this.location()?.coordinates ??
          this.mapboxService.coordsToPoint(this.mapboxService.DefaultMapCoords))
        : this.mapboxService.coordsToPoint(this.mapboxService.DefaultMapCoords);
    this.countries = getCountryListData();
  }

  getCoordinatesAndUpdateMap() {
    let isUpdateMap = false;

    let coordinates: Point = new Point(0, 0);

    coordinates.x =
      this.location()?.coordinates?.x ?? this.mapboxService.DefaultMapCoords[0];
    isUpdateMap = true;
    coordinates.y =
      this.location()?.coordinates?.y ?? this.mapboxService.DefaultMapCoords[1];
    isUpdateMap = true;

    if (isUpdateMap) {
      this.updateMap();
    }

    return coordinates;
  }

  initMap() {
    this.map = new mapboxgl.Map(this.mapboxSettings());
    if (!this.isCreate)
      this.marker.setLngLat(this.mapboxSettings().center).addTo(this.map);

    if (!this.showMapOnly && this.showLocationSearch) {
      // TODO: figure out what is geocoder2 for, why can't we just use geocoder1?
      // const geoCoderId = 'geocoder1';
      const geoCoderId = this.isCreate ? 'geocoder1' : 'geocoder2';
      const geoCoderElement = document.getElementById(geoCoderId);

      // console.log('geoCoderElement: ', geoCoderElement); // DEBUG

      if (geoCoderElement) {
        // Remove all children before adding geocoder
        while (geoCoderElement?.lastElementChild) {
          geoCoderElement.removeChild(geoCoderElement.lastElementChild);
        }
        this.geocoder.addTo(`#${geoCoderId}`);
      }
    }

    this.geocoderInputString =
      this.formValue()?.streetAddress1 +
      ', ' +
      this.formValue()?.zip +
      ', ' +
      this.formValue()?.city +
      ',' +
      this.formValue()?.country;

    this.marker.on('dragend', () => {
      const lngLat = this.marker.getLngLat();
      this.coordinates = this.mapboxService.lngLatToPoint(lngLat);
    });
    this.geocoder.on('result', (resultResponse: any) => {
      this.marker.remove();
      this.map.flyTo({
        center: resultResponse.result.center,
        speed: 1.5,
        essential: false,
        zoom: 15,
      });
      this.marker.setLngLat(resultResponse.result.center).addTo(this.map);
      this.marker.setDraggable(true);
      this.editingLocation.set(true);
      this.mapboxResponse = resultResponse.result;
    });
    this.map.resize();
  }

  editOnTheMap() {
    const geocoderSearch =
      this.formValue()?.streetAddress1 +
      ', ' +
      this.formValue()?.zip +
      ', ' +
      this.formValue()?.city +
      ',' +
      this.formValue()?.country;
    this.geocoder.query(geocoderSearch);

    this.editLocation();
  }
  editLocation() {
    this.marker.setDraggable(true);
    this.editingLocation.set(true);
  }
  cancelEditing() {
    this.editingLocation.set(false);
    this.geocoder.getPlaceholder();
    this.marker.setDraggable(false);
  }

  setAddressToMapResponse() {
    // this.editLocation();

    this.coordinates = this.mapboxService.lngLatToPoint(
      this.marker.getLngLat(),
    );

    // this.ngForm()?.form.markAsDirty();

    this.formValue.update((values) => ({
      ...values,
      streetAddress1: `${this.mapboxResponse.text} ${
        this.mapboxResponse.address ? this.mapboxResponse.address : ''
      }`,
      zip: this.filterResponse('postcode'),
      country: this.getCountry() ?? '',
      city: this.filterResponse('place'),
      region: this.filterResponse('region'),
      coordinates: new Point(this.coordinates.x, this.coordinates.y),
    }));

    this.updateMap();
    this.editingLocation.set(false);
    // this.formDirty.set(true);
  }

  filterResponse(id: string) {
    const value = _.filter(this.mapboxResponse.context, (item) => {
      return item.id.indexOf(id) > -1;
    });
    return value.length ? value[0].text : null;
  }

  updateMap() {
    if (this.marker && this.map) {
      const center = new mapboxgl.LngLat(
        this.formValue()?.coordinates?.x ??
          this.mapboxService.DefaultMapCoords[0],
        this.formValue()?.coordinates?.y ??
          this.mapboxService.DefaultMapCoords[1],
      );

      this.marker.remove();
      this.map.flyTo({
        center,
        speed: 1.5,
        essential: true,
        zoom: 15,
      });
      this.marker.setLngLat(center).addTo(this.map);
      this.map.resize();
    }
  }

  getCountry() {
    // mapbox returns only 'United States', however, the list is expecting the full name.
    const value = _.filter(this.mapboxResponse.context, (item) => {
      return item.id.indexOf('country') > -1;
    });

    if (value.length) {
      const matched = this.countries.filter(
        (country) => country?.code.toLowerCase() === value[0].short_code,
      );
      return matched.length ? matched[0].name : null;
    }

    return null;
  }

  openDeleteLocationDialog() {
    console.log('openDeleteLocationDialog'); // DEBUG

    if (this.location() && this.location()!.id) {
      const modal = this.modalService.open(DeleteLocationDialogComponent);
      // const componentInstance = modal.componentInstance as DeleteLocationDialogComponent;
      modal.result
        .then(async () => {
          await this.locationsStore
            .deleteLocation(this.location()!.id!)
            .then(() => this.closeSlidingPanel.emit());
        })
        .catch(() => {});
    }
  }

  async onSubmit(): Promise<void> {
    this.formValue.update((values) => ({
      ...values,
      coordinates: new Point(this.coordinates.x, this.coordinates.y),
    }));

    if (this.isCreate) {
      await this.locationsStore
        .createLocation(this.formValue()!)
        .then((result: Location | null) => {
          if (result === null) return;
          this.formValue.update((values) => ({ ...values, id: result.id }));
        });
      this.locationSaved.emit(this.formValue()!);
      this.closeSlidingPanel.emit();
      return this.closeParentModal.emit(this.formValue()!);
    }

    this.formValue.update((values) => ({ ...values, id: this.location()?.id }));
    await this.locationsStore.updateLocation(this.formValue()!);
    this.locationsStore.setUnsavedChanges(false);
    this.formDirty.set(false);
  }
}
