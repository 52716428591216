import {
  Component,
  HostBinding,
  contentChild,
  inject,
  signal,
} from '@angular/core';
import { NgModel, NgModelGroup } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [TranslatePipe],
  selector: '[controlWrapper]',
  template: `
    <div class="input-wrapper__content">
      <ng-content />
    </div>
    <div class="input-wrapper__errors">
      @if (ngModel()?.control?.errors && ngModel()?.touched) {
        @for (error of ngModel()?.control?.errors?.['errors']; track error) {
          <span class="me-2 error">{{ error | translate }}</span>
        }
      }
      @if (ngModelGroup()?.control?.errors && ngModel()?.touched) {
        @for (
          error of ngModelGroup()?.control?.errors?.['errors'];
          track error
        ) {
          <span class="me-2 error">{{ error | translate }}</span>
        }
      }
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        padding-bottom: 0.25rem;
      }

      .input-wrapper__errors {
        span {
          position: absolute;
          margin-left: 0.5rem;
        }
      }
    `,
  ],
})
export class ControlWrapperComponent {
  public ngModel = contentChild<NgModel>(NgModel);
  public readonly ngModelGroup = signal<NgModelGroup | null>(
    inject(NgModelGroup, {
      optional: true,
      self: true,
    }),
  );

  @HostBinding('class.input-wrapper--invalid')
  public get invalid() {
    if (
      (!this.ngModelGroup &&
        this.ngModel()?.control?.errors &&
        this.ngModel()?.touched) ||
      (this.ngModelGroup()?.control?.errors && this.ngModelGroup()?.touched)
    ) {
      return true;
    }
    return false;
  }
}
